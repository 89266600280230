import * as React from 'react';

import styled from 'styled-components';
import { Card, Row, Col } from 'antd';

import Content from '../content';

const Wrapper = styled.div`
	
`;

const Heading = styled.div`
	background: var(--color-accent) ;
	
`;

const HeroContent = styled(Content)`
	background: transparent;
	padding: 30px 0 60px 0;
	
	.ant-col {
		margin: 0 auto;
	}
	
	h1 {
		color: black;
		text-align: center;
		font-size: 3.3rem;
		font-weight: 800;
		padding: 80px 30px 10px 30px;
		margin: 0 auto;
		max-width: 900px;

		span {
			color: white;
			background: var(--color-primary);
		}
	}

	@media (max-width: 990px) {
		padding: 0;
		
		h1 {
			padding: 20px 0 0 0;
			font-size: 2.3rem;
			text-align: center;
			margin-bottom: 20px;
		}
	}
	
	
`;

interface Props {
	title: string;
}

interface State {

}

export default class Hero extends React.Component<Props & React.HTMLAttributes<HTMLDivElement>, State> {

	render() {
		return <Wrapper {...this.props}>
			<Heading>
				<HeroContent>
					<Row gutter={20}>
						<Col>
							<h1>{this.props.title}</h1>
						</Col>
					</Row>
				</HeroContent>
			</Heading>
		</Wrapper>;
	}
}
