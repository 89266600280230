import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'gatsby';

import styled from 'styled-components';
import {
	DesktopOutlined,
	DollarCircleOutlined,
	MailOutlined,
	QuestionCircleOutlined,
	MenuOutlined,
} from '@ant-design/icons';
import { Button, Menu, Layout } from 'antd';

import Logo from '@/components/logo';
import { State as RootState } from '@/types';
import { logout } from '@/state/reducers/auth';
import Content from '../content';

const Wrapper = styled.div`
	.nav-container {
		box-shadow: 0 4px 8px 0 rgba(0, 0, 0, .2);
		background-color: white;
	}

	.nav-logo {
		float: left;
		width: 200px;
		padding-left: 20px;
	}

	.nav-items {
		justify-content: flex-end;
	}

	// 990 breakpoint of title for the logo component
	@media only screen and (max-width: 990px) {
		.nav-logo {
			width: 70px;
		}
	}
`;

const NavContent = styled(Content)`
	background: transparent;
`;

const WMenu = styled(Menu)`
	border: none;
	width: auto;

	li {
		line-height: 64px;
	}

	.ant-menu-item, .ant-menu-overflow-item {
		&::after {
			border-bottom: none !important;
		}
	}
`;

interface Props {

}

interface State {
	affixTop: number;
}

export class PublicNav extends React.Component<Props & React.HTMLAttributes<HTMLDivElement>, State> {
	state = {
		affixTop: 0,
	};

	static get selectedMenuItem() {
		if (typeof window !== 'undefined') {
			switch (window.location.pathname) {
				case '/':
					return ['1'];
				case '/features':
					return ['2'];
				case '/pricing':
					return ['3'];
				default:
					return ['1'];
			}
		}
	}

	render() {
		return (
			<Wrapper>
				<NavContent>
					<div className={'nav-container'}>
						<div className={'nav-logo'}>
							<Link to={'/'}>
								<Logo width={30} style={{
									verticalAlign: 'middle',
								}} imgStyles={{
									verticalAlign: 'middle',
								}} title={'DnsAdBlock'} theme={'dark'}/>
							</Link>
						</div>

						<WMenu
							theme="light"
							mode="horizontal"
							defaultSelectedKeys={PublicNav.selectedMenuItem}
							overflowedIndicator={<MenuOutlined/>}
							className={'nav-items'}
						>
							<Menu.Item key={7}>
								<DesktopOutlined/> <Link
								to={'/features/'}><b>Features</b></Link>
							</Menu.Item>

							<Menu.Item key={5}>
								<QuestionCircleOutlined/> <Link
								to={'/faq/'}><b>FAQ</b></Link>
							</Menu.Item>

							<Menu.Item key={6}>
								<DollarCircleOutlined/> <Link
								to={'/pricing/'}><b>Pricing</b></Link>
							</Menu.Item>

							<Menu.Item key={4}>
								<MailOutlined/> <Link to={'/support/'}><b>Support</b></Link>
							</Menu.Item>

							<Menu.Item key={5}>
								<Link to={'/app/login/'}>
									<Button type={'primary'}>Members</Button>
								</Link>
							</Menu.Item>
						</WMenu>

						<div style={{ clear: 'both' }}/>
					</div>
				</NavContent>
			</Wrapper>
		);
	}
}

const mapStateToProps = (state: RootState) => {
	return {};
};

const mapDispatchToProps = { logout };

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(PublicNav);
