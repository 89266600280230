import * as React from 'react';

import { Box, Flex } from '@rebass/grid';
import styled from 'styled-components';
import { Link } from 'gatsby';

import Content from '../content';

const Wrapper = styled.div`
	color: #fff;
	background: rgb(26, 29, 46);
	z-index: 9;
	
	h5 {
		font-size: 1.2rem;
		font-weight: 400;
		padding-bottom: 10px;
		margin-bottom: 20px;
		color: #fff;
		border-bottom: 2px solid rgb(45, 50, 78);
	}
	
	p {
		
	}
	
	.about {
		padding: 20px 0 20px 20px;
		font-weight: 300;
		color: #fff;
		line-height: 1.4rem;
		
		b {
			font-weight: 400;
			color: #fff;
		}
		border-top: 1px solid rgb(45, 50, 78);
	}
	
	ul {
		margin: 0;
		padding: 0;
		list-style-type: none;
		
		li {
			margin: 0;
			padding: 0;
			
			a {
				width: 100%;
				text-overflow: ellipsis;
				white-space: nowrap;
				overflow: hidden;
				font-weight: 300;
				display: inline-block;
				margin-bottom: 8px;
				padding-bottom: 4px;
				font-size: 1.2rem;
				border-bottom: 2px solid transparent;
				color: rgb(157, 171, 183);
				
				&:hover {
					text-decoration: none;
					color: #fff;
				}
			}
		}
	}
`;

interface Props {

}

interface State {

}

export default class Footer extends React.Component<Props & React.HTMLAttributes<HTMLDivElement>, State> {
	render() {
		return <Wrapper {...this.props}>
			<Content style={{ background: 'transparent' }}>
				<Flex alignItems={'top'} pt={40} pb={60} flexWrap={'wrap'}>
					<Box width={[1, 1, 1, 1 / 3]} mt={4} px={4}>
						<h5>Support &amp; Knowledgebase</h5>
						<ul>
							<li>
								<Link to={'/support/'}>Contact us</Link>
							</li>
							<li>
								<a href={'https://knowledgebase.dnsadblock.com'}>Knowledgebase</a>
							</li>
							<li>
								<Link to={'/faq/'}>Frequently asked questions</Link>
							</li>
						</ul>
					</Box>
					<Box width={[1, 1, 1, 1 / 3]} mt={4} px={4}>
						<h5>Resources</h5>
						<ul>
							<li>
								<Link to={'/tos/'}>Terms of Service</Link>
							</li>
							<li>
								<Link to={'/articles/'}>Articles</Link>
							</li>
							<li>
								<Link to={'/dns-leak-test/'}>DNS leak test</Link>
							</li>
						</ul>
					</Box>
					<Box width={[1, 1, 1, 1 / 3]} mt={4} px={4}>
						<h5>Use cases</h5>
						<ul>
							<li>
								<Link to={'/ad-blocker/'}>Ad blocker</Link>
							</li>
							<li>
								<Link to={'/porn-blocker/'}>Porn blocker</Link>
							</li>
							<li>
								<Link to={'/block-social-media/'}>Block social media</Link>
							</li>
							<li>
								<Link to={'/for-parents/'}>For parents</Link>
							</li>
							<li>
								<Link to={'/internet-filtering-schools/'}>Internet filtering for
									schools</Link>
							</li>
							<li>
								<Link to={'/internet-filter-office/'}>For work offices</Link>
							</li>
						</ul>
					</Box>
				</Flex>
			</Content>

			<Flex className={'about'}>
				<Box>
					<b>dnsadblock.com</b> is owned and operated by <b>DR SOFT S.R.L</b> Bucharest, Romania
				</Box>
			</Flex>
		</Wrapper>;
	}
}
