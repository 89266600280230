import React from 'react';
import { graphql, Link, navigate } from 'gatsby';

import { Card } from 'antd';
import styled from 'styled-components';

import SEO from '@/components/seo';
import Hero from '@/components/public/page-hero';
import Layout from '@/components/layout/public';
import Content from '@/components/content';

const Title = styled.b`
	color: black;
	font-weight: 800;
`;

interface Props {
	data: {
		allMarkdownRemark: {
			edges: any;
		}
	};
}

export default function BlogIndexPage(props: Props) {
	return (
		<Layout>
			<SEO
				title={'Articles by DnsAdBlock'}
			/>
			<Hero title={'Articles'} style={{ marginBottom: 50 }}/>
			<Content style={{ marginBottom: 40 }}>
				{props.data.allMarkdownRemark.edges.map((edge: any) => (
					<Card title={
						<Link to={edge.node.frontmatter.slug}>
							<Title>{edge.node.frontmatter.title}</Title>
						</Link>
					} hoverable onClick={() => navigate(edge.node.frontmatter.slug)}
						style={{
							marginTop: 20,
						}}
					>
						<div>{edge.node.excerpt}</div>
					</Card>
				))}
			</Content>
		</Layout>
	);
}

export const pageQuery = graphql`
	query {
		allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
			edges {
				node {
					id
					excerpt(pruneLength: 250)
					frontmatter {
						date(formatString: "MMMM DD, YYYY")
						slug
						title
					}
				}
			}
		}
	}
`;
