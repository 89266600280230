import * as React from 'react';

import styled from 'styled-components';
import { Layout } from 'antd';

import PublicNav from '../nav/public';

import BaseLayout from './base';
import Footer from '../footer';

const NavWrapper = styled(Layout.Header)`
	padding   : 0;
	z-index: 1;
`;

const Wrapper = styled(Layout)`
	min-height: 100%;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	
	main {
		flex-grow: 1;
	}
	
	header {
		background: var(--color-accent);
		padding-bottom: 10px;
	}
	
	header, main, footer {
		flex-shrink: 0;
	}
`;

interface Props {
	title?: string;
}

interface State {

}

export default class PublicLayout extends React.Component<Props & React.HTMLAttributes<HTMLDivElement>, State> {
	render() {
		return <BaseLayout>
			<Wrapper>
				<header>
					<NavWrapper>
						<PublicNav/>
					</NavWrapper>
				</header>

				<main>
					{this.props.children}
				</main>

				<footer>
					<Footer/>
				</footer>
			</Wrapper>
		</BaseLayout>;
	}
}
